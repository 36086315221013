import { DOCUMENT_STATUS_TYPES, DOCUMENT_TYPE_TYPES } from "../enums";

export const DOCUMENT_TYPES: DOCUMENT_TYPE_TYPES[] = [
  DOCUMENT_TYPE_TYPES.FACE,
  DOCUMENT_TYPE_TYPES.Progress,
  DOCUMENT_TYPE_TYPES.Discharge,
  DOCUMENT_TYPE_TYPES.Assessment,
  DOCUMENT_TYPE_TYPES.POS,
  DOCUMENT_TYPE_TYPES.IFSP,
  DOCUMENT_TYPE_TYPES.Other,
];

export const DOCUMENT_STATUSES: DOCUMENT_STATUS_TYPES[] = [
  DOCUMENT_STATUS_TYPES.Pending,
  DOCUMENT_STATUS_TYPES.Submitted,
  DOCUMENT_STATUS_TYPES['Report Past due'],
  DOCUMENT_STATUS_TYPES['Notes Past due'],
  DOCUMENT_STATUS_TYPES.Completed,
];

export const SPECIALITY_CODES_ARRAY = ['OT', 'PT', 'ST', 'CDS', 'INT'];
